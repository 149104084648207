import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Darkmode from "./Darkmode";
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import logo from "../../img/logo.png";

const Navbarmenu = () => {
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);

  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }

  const [isMenuSubMenu, setMenuSubMenu] = useState(false);
  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };

  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }

  return (
    <header
      className="header__middle"
      style={{
        backgroundColor: "#800000",
        position: "sticky",
        fontFamily: "Poppins",
        top: "0",
        zIndex: "1000",
        boxShadow: "-5px -7px 10px #F8EDED, 0 0 20px #F8EDED",
        border: "none",
      }}
    >
      <div className="container">
        <div
          className="header__middle__container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Logo Section */}
          <div
            className="header__middle__logo"
            style={{ display: "flex", alignItems: "center" }}
          >
            <NavLink exact="true" activeclassname="is-active" to="/">
              <img
                className="animate__animated animate__zoomIn"
                src={logo}
                style={{
                  width: "60px",
                  cursor: "pointer",
                  paddingBottom: "20px",
                }}
                alt="logo"
              />
            </NavLink>
          </div>

          {/* Text Section */}
          <div
            className="header__middle__text"
            style={{
              position: "relative",
              fontSize: "20px",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              cursor: "pointer", // Add pointer to indicate it's clickable
            }}
          >
            <NavLink
              exact="true"
              activeclassname="is-active"
              to="/" // Redirect to home page
              style={{ color: "inherit", textDecoration: "none",overflow:"hidden"
              }} >
             <h1 class="animate__animated animate__backInLeft" style={{color:"#ffffff",fontSize:"27px"}}> श्री श्री छठपूजा समिति !</h1>
              <div
                style={{
                  position: "absolute",
                  top: "-18px",
                  right: "-30px",
                  fontSize: "14px",
                  color: "#fff455",                  
                }}
              >
                <p class="animate__animated animate__backInRight" style={{fontSize:"17px"}}>संझौली</p>
               
              </div>
            </NavLink>
          </div>

          {/* Menu Section */}
          <div className="header__middle__menus">
            <nav className="main-nav ">
              {/* Responsive Menu Button */}
              {isResponsiveclose === true ? (
                <span
                  className="menubar__button"
                  style={{ display: "none" }}
                  onClick={toggleClass}
                >
                  <FiXCircle />
                </span>
              ) : (
                <span
                  className="menubar__button"
                  style={{ display: "none" }}
                  onClick={toggleClass}
                >
                  <FiAlignRight />
                </span>
              )}

              <ul className={boxClass.join(" ")}>
                <li className="menu-item">
                  <NavLink
                    exact="true"
                    activeclassname="is-active"
                    onClick={toggleClass}
                    to={`/sanjhauli/Donate`}
                  >
                    Donate
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    onClick={toggleClass}
                    activeclassname="is-active"
                    to={`/book-place`}
                  >
                    Book Place
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    onClick={toggleClass}
                    activeclassname="is-active"
                    to={`/sanjhauli/View_list`}
                  >
                    View List
                  </NavLink>
                </li>
                <li className="menu-item">
                  <NavLink
                    onClick={toggleClass}
                    activeclassname="is-active"
                    to={`/sanjhauli/Gallery`}
                  >
                    Gallery
                  </NavLink>
                </li>
                <li
                  onClick={toggleSubmenu}
                  className="menu-item sub__menus__arrows"
                >
                  <Link to="#">
                    More <FiChevronDown />
                  </Link>
                  <ul
                    className={boxClassSubMenu.join(" ")}
                    style={{ backgroundColor: "#800000" }}
                  >
                    <li>
                      <NavLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/sanjhauli/About`}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/sanjhauli/Work`}
                      >
                        How We Work?
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/sanjhauli/Contact`}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/sanjhauli/Admin`}
                      >
                        Admin Panel
                      </NavLink>
                    </li>
                    {localStorage.getItem("token") !== null && (
                      <li>
                        <NavLink
                          onClick={() => {
                            console.log(localStorage.getItem("token"));
                            localStorage.removeItem("token");
                            toggleClass();
                          }}
                          activeclassname="is-active"
                          to={`/sanjhauli/Donate`}
                        >
                          Log Out
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>

                <li
                  className="menu-item"
                  style={{
                    marginTop: "-8px",
                  }}
                >
                  <Darkmode />
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbarmenu;
