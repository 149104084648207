import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";

function DonorsDetails() {
  const [donors, setDonors] = useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    // localStorage.setItem("admin", "true");
    if (localStorage.getItem("admin") !== "true") {
      navigate("/");
    }
  }, []);

  // Function to format date as "dd-mm-yyyy"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Fetch donor details
  useEffect(() => {
    axios
      .get("https://wms-sf6o.onrender.com/donors")
      .then((res) => {
        setDonors(res.data);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // Function to delete a donor
  const handleDelete = (id, donor_id) => {
    // update donor list by removing the donor based on id
    const updatedDonors = donors.filter((donor) => donor.id !== id);
    // /donors/:id DELETE
    axios
      .post(`https://wms-sf6o.onrender.com/donors/update`, {
        status: "Rejected",
        id: donor_id,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data === "Updated") {
          setDonors(updatedDonors);
        }
      })
      .catch((err) => console.log(err));
  };

  // Function to add donor data to another page
  const handleAddToAnotherPage = (id, donor_id) => {
    const updatedDonors = donors.filter((donor) => donor.id !== id);
    axios
      .post(`https://wms-sf6o.onrender.com/donors/update`, {
        status: "Approved",
        id: donor_id,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data === "Updated") {
          setDonors(updatedDonors);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div
        className="container"
        style={{
          textAlign: "center",
          padding: "10px 50px",
          display: "flex",
          justifyContent: "center",
          marginTop:"20px",
        }}
      >
        <div
          className="table-heading"
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "20px 20px 0px 0px",
          }}
        >
          <h3
            style={{
              color: "#800000",
            }}
          >
            DONORS STATUS
          </h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Amount</th>
                <th>Date</th>
                <th>UTR</th>
                <th>Acc No</th>
                <th>Deposit Proof</th>
                <th colSpan={2}>Action</th>
              </tr>
            </thead>
            <tbody>
            {donors?.map((donor, index) => (
              donor.donors_details.map((details, i) => (
                <tr key={`${donor.id}-${i}`}>
                  <td>{donor.name}</td>
                  <td>{donor.address}</td>
                  <td>{details.amount}</td>
                  <td>{formatDate(details.date)}</td>
                  <td>{details.utrno}</td>
                  <td>{details.accno}</td>
                  <td>{details.deposit_proof}</td>
                  <td>
                    <input
                      type="button"
                      style={{ background: "none", color: "red" }}
                      value={"❌"}
                      onClick={() => handleDelete(donor.id, details.id)}
                    />
                  </td>
                  <td>
                    <input
                      type="button"
                      style={{ background: "none", color: "green" }}
                      value={"➕"}
                      onClick={() => handleAddToAnotherPage(donor.id, details.id)}
                    />
                  </td>
                </tr>
              ))
            ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default DonorsDetails;
