import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './WhyBihariPoojaPath.css'; 

function BihariPoojaPath() {
  const benefits = [
    {
      imgSrc: '../assets/images/safe.png',
      title: 'Easy To Host Your Committee.',
      description: 'Bihari Pooja Path hosts worship committees like Durga Pooja, Chhath Pooja, Bajrang Dal,etc. ensuring highly qualified, well-behaved members. It also securely stores all past and current data for each worship committee, keeping everything organized and accessible.'
    },
    {
      imgSrc: '../assets/images/quick service.png',
      title: 'Quick and Convenient',
      description: 'Bihari Pooja Path is swiftly here for your support, offering quick and reliable assistance with every need. Our dedicated platform ensures seamless access to information and services for your spiritual and cultural activities, making your experience effortless and meaningful.'
    },
    {
      imgSrc: '../assets/images/quality.png',
      title: 'Quality Assurance',
      description: 'We encourage all members of our hosted committees to continually enhance the quality of their work related to their specific worship practices. Strive for excellence, uphold the traditions, and ensure every event is a true reflection of dedication and reverence. Let’s make every worship experience meaningful and memorable for all.'
    },
    {
      imgSrc: '../assets/images/best service.png',
      title: 'Best Service',
      description: 'We ensure our hosted committees deliver exceptional services, catering to the needs of every devotee with utmost care and devotion. Our commitment is to create a seamless experience that upholds the highest standards of hospitality and spiritual fulfillment.'
    },
  ];

  return (
    <div className="why-bihari-pooja-path">
      <h3 className="text-center mb-4">WHY BIHARI POOJA PATH</h3>
      <div className="container">
        <div className="row">
          {benefits.map((benefit, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className=" h-100 text-center">
                <div className="card-body">
                  <img src={benefit.imgSrc} alt={benefit.title} className="img-fluid mb-3 icon-image" />
                  <h5 className="card-title">{benefit.title}</h5>
                  <p className="card-text">{benefit.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BihariPoojaPath;
