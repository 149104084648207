import React, { useRef } from "react";
import Carousel from "react-bootstrap/Carousel";
import slide01 from "./carousel_images/slide01.jpg";
import slide02 from "./carousel_images/slide02.jpg";
import slide03 from "./carousel_images/slide03.jpg";
import slide04 from "./carousel_images/slide04.jpg";
import slide05 from "./carousel_images/slide05.jpg";
import slide06 from "./carousel_images/slide06.jpg";
import slide07 from "./carousel_images/slide07.jpg";
import slide08 from "./carousel_images/slide08.jpg";
import slide09 from "./carousel_images/slide09.jpg";
import slide10 from "./carousel_images/slide10.jpg";
import slide11 from "./carousel_images/slide11.jpg";
import "./Carousel_page.css";

function Carousel_page() {
  const carouselRef = useRef(null); // Reference to the Carousel component

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev(); // Programmatically go to the previous slide
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next(); // Programmatically go to the next slide
    }
  };

  return (
    <>
      <div className="container">
        <div className="carousel">
          <Carousel ref={carouselRef} controls={false}>
            <Carousel.Item>
              <img className="d-block" src={slide01} alt="first slide" />
              <Carousel.Caption>
                <h3 className ="animate__animated animate__zoomIn" >छठव्रती!</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block" src={slide02} alt="second slide" />
              <Carousel.Caption>
                <h3  >
                  सूर्यदेव को अर्घ्य देते छठव्रती!
                </h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block" src={slide03} alt="third slide" />
              <Carousel.Caption>
                <h3  >
                  छठ पर्व करते छठव्रती!
                </h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block" src={slide04} alt="fourth slide" />
              <Carousel.Caption>
                <h3  >
                  छठ पूजा के दौरान पूजा करती महिला!
                </h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block" src={slide05} alt="fifth slide" />
              <Carousel.Caption>
                <h3  >
                  उत्सव का स्वरूप!
                </h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block" src={slide06} alt="sixth slide" />
              <Carousel.Caption>
                <h3  >उषा अर्घ्य!</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block" src={slide07} alt="seventh slide" />
              <Carousel.Caption>
                <h3  >संध्या अर्घ्य!</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block" src={slide08} alt="eighth slide" />
              <Carousel.Caption>
                <h3  >सूर्य देवता की आराधना!</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block" src={slide09} alt="ninth slide" />
              <Carousel.Caption>
                <h3  >प्रसाद! (ठेकुआ)</h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block" src={slide10} alt="tenth slide" />
              <Carousel.Caption>
                <h3  >
                  दौरा घाट ले जाते हुए परिजन!
                </h3>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block" src={slide11} alt="eleventh slide" />
              <Carousel.Caption>
                <h3  >छठ पर्वती</h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          {/* Custom Controls */}
          <button className="carousel-control-prev" onClick={handlePrev}>
            &lt; {/* Replace with an icon or arrow */}
          </button>
          <button className="carousel-control-next" onClick={handleNext}>
            &gt; {/* Replace with an icon or arrow */}
          </button>
        </div>
      </div>
    </>
  );
}

export default Carousel_page;
