import React from "react";
import DonorsDetails from "./Donors";
import {Link, useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Adminpanel() {
  const navigate = useNavigate();
  React.useEffect(() => {
    // localStorage.setItem("admin", "true");
    if (localStorage.getItem("admin") !== "true") {
      navigate("/");
    }
  }, []);

  return (
    <div
      className="container"
      style={{
        display: "flex",
        justifyContent: "center",
        columnGap: "20px",
        alignItems: "center",
        top: "0",
        bottom: "0",
        margin: "auto",
      }}>
      {/* path to redirect on donor details code start */}
      <Link
        to="/reserve-place"
        className="btn mb-3 animate__animated animate__zoomIn"
        style={{
          margin: "5px",
          fontWeight: "bold",
        }}>
        <img
          width="50"
          height="50"
          src="https://img.icons8.com/ios/50/place-marker--v1.png"
          alt="place-marker--v1"
        />
      <span style={{color:"#ffffff" , cursor:"pointer"}}>Reserve Place</span>
      </Link>
      {/* path to redirect on donor details code end */}
      {/* path to redirect on Add donor details code start */}
      <Link
        to="/add-donor"
        className="btn mb-3 animate__animated animate__zoomIn"
        style={{
          margin: "5px",
          fontWeight: "bold",
        }}>
               <i class="fa-solid fa-user-plus fa-2xl" style={{color: "#ffffff"}}></i>

      <span style={{color:"#ffffff" , cursor:"pointer"}}>Add Donors</span>
      </Link>
      {/* path to redirect on Add donor details code end */}
      {/* path to redirect on verify donor details code start */}
      <Link
        to="/donors-details"
        className="btn mb-3 animate__animated animate__zoomIn"
        style={{
          margin: "5px",
          fontWeight: "bold",
        }}>
        <img
          width="50"
          height="50"
          src="https://img.icons8.com/dotty/80/available-updates.png"
          alt="available-updates"
        />
      <span style={{color:"#ffffff" , cursor:"pointer"}}>Verify Donors</span>
      </Link>
      {/* path to redirect on verify donor details code end */}
    </div>
  );
}

export default Adminpanel;
