import { Link } from "react-router-dom";
import React from "react";
import styles from "./Footer.module.css"; // Import CSS modules for styling

function Footer() {
  const currentYear = new Date().getFullYear(); // Dynamically get the current year

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <p>&copy; {currentYear} biharipoojapath.com. All Rights Reserved.</p>
        <nav>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <Link to="/sanjhauli/About">About</Link>
            </li>
            <li>
              <Link to="/sanjhauli/Contact">Contact</Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
