import React from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import  styles from "./Contact.module.css";

<link
  href="https://fonts.googleapis.com/css2?family=Rubik+Scribble&display=swap"
  rel="stylesheet"
></link>;
const Contact = () => {
  return (
    <>
      <div className="container">
          {/* heading section */}
      <div className="heading">
        {/* email img  */}
        <div className="email" style={{ marginTop: "45px" }}>
          <a href="mailto:rajanunltd@gmail.com">
            <img
              className="animate__animated animate__zoomIn"
              style={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                margin: "auto",
                cursor: "pointer",
              }}
              width="50"
              height="50"
              src="https://img.icons8.com/bubbles/50/new-post.png"
              alt="new-post"
            />
          </a>
        </div>
        <div className={styles.section_a}>
          <h4
            class="animate__animated animate__heartBeat"
            style={{ color: "#fff455" }}
          >
            Happy to help you!
          </h4>
          <p>
            Call us at{" "}
            <a
              href="tel:+91 62060 11605"
              style={{
                textDecoration: "none",
                color: "#fff455",
              }}
            >
              +91 62060 11605
            </a>
            , email on{" "}
            <a
              href="mailto:contact@biharipoojapath.com"
              style={{
                textDecoration: "none",

                color: "#fff455",
              }}
            >
              contact@biharipoojapath.com
            </a>{" "}
            or submit a ticket
            <br />
            <br /> below, and our team will get back shortly.
            <br />{" "}
          </p>
        </div>
        <div className="list_item">
          <h4
            class="animate__animated animate__shakeX"
            style={{
              color: "red",
              fontFamily: "Poppins",
              color: "#fff455",
              textAlign: "center",
            }}
          >
            {" "}
            How Can We Help You?{" "}
          </h4>
        </div>
      </div>
      {/* section 1 [list & form] */}
      <div className={styles.main}>
        <div className="left-side">
          <ul>
            <li
              className="animate__animated animate__fadeInRight"
              style={{ listStyleType: "circle", fontSize: "30px" }}
            >
              {" "}
              I have a hot tip.
            </li>
            <li
              className="animate__animated animate__fadeInLeft"
              style={{ listStyleType: "circle", fontSize: "30px" }}
            >
              {" "}
              need something else.
            </li>
            <li
              className="animate__animated animate__fadeInRight"
              style={{ listStyleType: "circle", fontSize: "30px" }}
            >
              {" "}
              want to report a correction.
            </li>
            <li
              className="animate__animated animate__fadeInLeft"
              style={{ listStyleType: "circle", fontSize: "30px" }}
            >
              {" "}
              want to report a bug or technical issue.
            </li>
            <li
              className="animate__animated animate__fadeInRight"
              style={{ listStyleType: "circle", fontSize: "30px" }}
            >
              want to advertise on{" "}
              <span style={{ color: "#fff455", cursor: "pointer" }}>
                biharipoojapath.com
              </span>
              .
            </li>
          </ul>
        </div>

        <div className="right-side">
          <Form action="">
            <h4 class="animate__animated animate__zoomIn">
              Send Your Issue...
            </h4>

            <hr
              style={{
                borderTop: "5px dotted #F8EDED",
                opacity: "0.9",
              }}
            />

            <Form.Group className="mb-3" controlId="form-label">
              <Form.Control type="text" placeholder="Enter Full Name..." />
            </Form.Group>

            <Form.Group className="mb-3" controlId="form-label">
              <Form.Control type="number" placeholder="Enter Mobile No." />
            </Form.Group>

            <Form.Select className="mb-3" aria-label="Default select example">
              <option>Select Your Category... </option>
              <option value="1">I have a hot tip.</option>
              <option value="2">need something else.</option>
              <option value="3">want to report a correction.</option>
              <option value="4">
                want to report a bug or technical issue.
              </option>
              <option value="5">
                want to advertise on biharipoojapath.com.
              </option>
            </Form.Select>

            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Control type="email" placeholder="Enter Email" />
            </Form.Group>

            <FloatingLabel
              controlId="floatingTextarea2"
              label="Leave a message "
            >
              <Form.Control as="textarea" style={{ height: "100px" }} />
            </FloatingLabel>

            <input
              className={styles.btn}
              type="button"
              value="Send"
              id="send-button"
              onclick="Send()"
              style={{
                marginTop: "20px",
                width: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                borderRadius: "5px",
                backgroundColor: "#ffffff",
                fontWeight: "600",
                color: "#800000",
              }}
            />
            <br />
          </Form>
        </div>
      </div>

      {/* section 2 [get in touch & Map] */}
      <div className={styles.main}>
        <div className="left-side">
              
            <h4 class="animate__animated animate__fadeInLeft">
              Let's get in touch
            </h4>
            <p
              style={{
                fontFamily: "Poppins",
                textAlign: "center",
                padding:"0 5px",
              }}>
              Want to get in touch? Let's chat, our entire team would love to
              hear from you. So, just pick up the phone or start a coversation
              over a mail using the information shared below.
            </p>
            <br />
            <p
              style={{
                fontFamily: "Poppins",
              }}>
              You can also connect with us for business queries of bulk orders
              or stockists.
            </p>
            <br />
            <div
              className="contact-list"
              style={{
                fontFamily: "Poppins",
              }}>
              <p class="animate__animated animate__jackInTheBox">
                {" "}
                <span style={{color: "#fff455", fontSize: "15px"}}>
                  Email :
                </span>{" "}
                contact@biharipoojapath.com{" "}
              </p>
              <p class="animate__animated animate__jackInTheBox">
                {" "}
                <span style={{color: "#fff455", fontSize: "15px"}}>
                  Telephone :
                </span>{" "}
                +91 62060 11605
              </p>
              <p class="animate__animated animate__jackInTheBox">
                {" "}
                <span style={{color: "#fff455", fontSize: "15px"}}>
                  Customer Care :
                </span>{" "}
                +91 93049 19837
              </p>
            </div>
          
        </div>
        <div className="right-side mb-5 "> 
        <h4 class="animate__animated animate__fadeInRight">
              पूजा स्थल !
            </h4>
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1837636.858336046!2d84.4875357133984!3d25.89794901642395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed5844f0bb6903%3A0x57ad3fed1bbae325!2sBihar!5e0!3m2!1sen!2sin!4v1712391275507!5m2!1sen!2sin"
                loading="lazy"
                width="350px"
                height="300px"
                referrerpolicy="no-referrer-when-downgrade"
                style={{
                  border: "2px solid #F8EDED",
                  boxShadow: "5px 5px 20px #F8EDED, -2px -2px 0px #F8EDED",
                  borderRadius: "10px",
                }}
              />
            </div>
        </div>
      </div>
      </div>
    </>
  );  
};

export default Contact;
