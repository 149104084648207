import React from "react";
import Dropdown from "./Dropdown";
import Work from "../Work";
function TowK24() {
  return (
    <>
      <Work />
    </>
  );
}

export default TowK24;
