import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./Options.css";
import "@fortawesome/fontawesome-free/css/all.min.css";  // Import Font Awesome styles

function CustomDropdown({ title, items }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="list"
      style={{
        margin: "5px 0",
      }}>
      <Dropdown
        show={isOpen}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        style={{
          margin: "15px",
        }}>
        <Dropdown.Toggle
          id={`dropdown-button-${title}`}
          variant="secondary"
          style={{
            color: "#800000",
            backgroundColor: "#ffffff",
            borderRadius: "50px",
            fontWeight: "bold",
          }}
          onClick={() => setIsOpen(!isOpen)}>
          {title}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            textAlign: "center",
            background: "transparent",
            border: "none",
            padding: "10px",
          }}>
          {items.map((item, index) => (
            <div className="item-section" key={index}>
              <Dropdown.Item
                href={item.href}
                active={item.active}
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "50px",
                  maxWidth: "100px",
                  color: "#800000",
                  fontWeight: "bold",
                  border:"1.5px solid #000000"
                }}>
                {item.label}
              </Dropdown.Item>
            </div>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

function Options() {
  const dropdownData = [
    {
      title: "संझौली",
      items: [
        {label: "छठ पूजा", href: "#/action-1", active: true},
        {label: "दुर्गा पूजा", href: "#/action-2"},
        {label: "राम नवमी", href: "#/action-3"},
        {label: "साईं पूजा", href: "#/action-4"},
      ],
    },
    // सासाराम 
    {
      title: "सासाराम",
      items: [
        {label: "छठ पूजा", href: "#/action-1", active: true},
        {label: "दुर्गा पूजा", href: "#/action-2"},
        {label: "राम नवमी", href: "#/action-3"},
        {label: "साईं पूजा", href: "#/action-4"},
      ],
    },
    // // पीरो
    // {
    //   title: "पीरो",
    //   items: [
    //     {label: "छठ पूजा", href: "#/action-1", active: true},
    //     {label: "दुर्गा पूजा", href: "#/action-2"},
    //     {label: "राम नवमी", href: "#/action-3"},
    //     {label: "साईं पूजा", href: "#/action-4"},
    //   ],
    // },
    // नोखा
    {
      title: "नोखा",
      items: [
        {label: "छठ पूजा", href: "#/action-1", active: true},
        {label: "दुर्गा पूजा", href: "#/action-2"},
        {label: "राम नवमी", href: "#/action-3"},
        {label: "साईं पूजा", href: "#/action-4"},
      ],
    },
    // // गया 
    // {
    //   title: "गया ",
    //   items: [
    //     {label: "छठ पूजा", href: "#/action-1", active: true},
    //     {label: "दुर्गा पूजा", href: "#/action-2"},
    //     {label: "राम नवमी", href: "#/action-3"},
    //     {label: "साईं पूजा", href: "#/action-4"},
    //   ],
    // },
    // // More items here...
  ];

  const [isListVisible, setIsListVisible] = useState(false);

  return (
    <>
      <div
        className="dropdown-list"
        style={{
          display: "flex",
          justifyContent: "center",
        }}>
        <button
          className="center-button"
          onClick={() => setIsListVisible(!isListVisible)}
          style={{
            backgroundColor: "transparent", // Transparent button background
            border: "none", // Remove button border
            cursor: "pointer",
          }}>
          <i
            className={`fa ${
              isListVisible ? "fa-angle-double-up" : "fa-angle-double-down"
            }`}
            aria-hidden="true"
            style={{
              color: "#ffffff", // Icon color
              padding:"8px",
              backgroundColor: "transparent", // Transparent background
              fontSize: "24px", // Adjust the size as needed
            }}></i>
        </button>
      </div>

      {isListVisible && (
        <div
          className="dropdown-list"
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          {dropdownData.map((dropdown, index) => (
            <div className="Item" key={index}>
              <CustomDropdown title={dropdown.title} items={dropdown.items} />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default Options;
